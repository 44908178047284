import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Sample } from '@app/modules/service-data/service-data.types';
import { SampleUtilsService } from '@app/modules/service-data/services/sample-utils.service';
import { SampleService } from '@app/modules/service-data/services/sample.service';
import { SetSamplesAction } from '@app/modules/service-data/store/sample.actions';
import {
  NbButtonModule,
  NbCardModule,
  NbDialogRef,
  NbIconModule,
  NbInputModule,
  NbToastrService,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { inputAtLeastOneValidator } from '@shared/validators/input-at-least-one-validator';
import { map, switchMap } from 'rxjs';

@Component({
  selector: 'app-sample-search',
  standalone: true,
  imports: [
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    NbInputModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './sample-search.component.html',
})
export class SampleSearchComponent {
  protected searchForm = new FormGroup(
    {
      id: new FormControl<string>(''),
      kitId: new FormControl<string>(''),
      waybill: new FormControl<string>(''),
    },
    [inputAtLeastOneValidator()]
  );

  protected loading = false;

  constructor(
    private dialogRef: NbDialogRef<SampleSearchComponent>,
    private store: Store,
    private toastr: NbToastrService,
    private sampleService: SampleService,
    private sampleUtilsService: SampleUtilsService
  ) {}

  protected search() {
    if (this.searchForm.invalid) {
      return;
    }

    this.loading = true;

    this.sampleService
      .getSampleList(
        {
          ids: [this.searchForm.get('id').value],
          kitIds: [this.searchForm.get('kitId').value],
          waybillToken: this.searchForm.get('waybill').value,
        },
        {}
      )
      .pipe(
        map((samples) => samples.items),
        switchMap((samples: Sample[]) =>
          this.store
            .dispatch(new SetSamplesAction(samples))
            .pipe(map(() => samples))
        )
      )
      .subscribe({
        next: (samples: Sample[]) => {
          if (samples.length === 1) {
            this.sampleUtilsService.openDetails(samples[0].id);
            this.dismiss();
          } else if (samples.length > 1 && samples.length < 100) {
            this.sampleUtilsService.openResults(
              samples.map((sample: Sample) => sample.id)
            );
            this.dismiss();
          } else if (samples.length >= 100) {
            this.toastr.danger(
              `Too many results returned, narrow your search!`,
              'Too many results!',
              { duration: 5000 }
            );
          } else {
            this.toastr.danger(`Query returned no results!`, 'Not found!', {
              duration: 5000,
            });
          }
          this.loading = false;
        },
        error: () => {
          this.toastr.danger(`Query returned no results!`, 'Not found!', {
            duration: 5000,
          });
          this.loading = false;
        },
      });
  }

  dismiss() {
    this.dialogRef.close();
  }
}
